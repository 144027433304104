import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import orange from '@material-ui/core/colors/orange';

let theme = createMuiTheme({
  typography: {
    fontFamily: ['Russo One', 'Roboto', 'serif'].join(','),
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 500,
        fontSize: 48,
      },
      h2: {
        fontWeight: 500,
        fontSize: 32,
      },
      h3: {
        fontSize: 24,
      },
      subtitle1: {
        fontSize: 22,
      },
      body1: {
        fontFamily: ['Barlow', 'Roboto', 'serif'].join(','),
        fontSize: 18,
      },
      body2: {
        fontFamily: ['Barlow', 'Roboto', 'serif'].join(','),
      },
    },
  },
  palette: {
    primary: {
      main: '#2D4866',
    },
    secondary: blueGrey,
  },
});

theme = responsiveFontSizes(theme, {});
export default theme;
