import React from 'react';
import { Helmet } from 'react-helmet';
//import favico from '../images/favicon.ico';

export default function TopLayout(props) {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="slack-app-id" content="A011KDRQZNU" />
        {/*
        <link rel="icon" href={favico} type="image/x-icon" />
        */}
      </Helmet>
      {props.children}
    </>
  );
}
